body {
  margin: 0;
  font-family: 'Montserrat', "Circular Pro", 'Nunito',  -apple-system, BlinkMacSystemFont, "Segoe UI", 'Roboto', "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper>div {
  position: absolute;
  left: 0;
  right: 0;
}